
body {
    background-image: url(/public/images/background5.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: 'Dancing Script', cursive;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Shadows Into Light', cursive;
    box-sizing: border-box;
    text-decoration: none;
    list-style-type: none;
    width: 100%;
}

@media (max-width: 1294px) {
    body{
        background-image: url(/public/images/background5_tablet.png);
    }
}
@media (max-width: 645px) {
    body{
        background-image: url(/public/images/background5_phone.png);
    }
}


html {
    scroll-behavior: smooth;
}

.header {
    position: sticky;
    top: 0;
    width: 100%;
}

.Titulo{
    display: flex;
    justify-content: center;
    margin: 1cm 0 8.5cm 0;
}

div.Titulo p{
    color: #ffffff;
    display: flex;
    font-size: 4cm;
    margin: 0 0 0 0;
    font-family:'Shadows Into Light';
    font-style: italic;
    width: fit-content;
}

@media (min-width: 2225px) {
    div.Titulo p{
        font-size: 7cm;
        margin: 1cm 0 8.5cm 0;
    }
}
@media (max-width: 1294px) {
    div.Titulo p{
        font-size: 2cm;
        margin: 0.3cm 0 4cm 0;
    }
}
@media (max-width: 645px) {
    div.Titulo p{
        font-size: 1.5cm;
        margin: 0cm 0 4cm 0;
    }
}

div.Titulo p{
    transition: all 0.3s ease;
}

div.Titulo p:hover {
    color: rgb(255, 170, 0);
    text-shadow: rgb(255, 170, 0) 5px 0 10px;
    cursor: pointer;
    transform: scale(1.05);
}

.audio {
    display: flex;
    margin-top: 0cm;
    margin-left: 0cm;
}

.audio audio {
    width: 6cm;
}


.container-menu {
    display: flex;
    width: 100%;
    height: 30px;
    margin: 0 auto;
}

div.menu {
    position: relative;
    align-content: center;
    margin: 0 auto;
}

.section-heading{
    text-transform: uppercase;
    top: 0;
}

.header {
    display:fixed;
    position: sticky;
    align-items:baseline;
    justify-content: space-evenly;
    background-color: #ffffff;
    height: 30px;
    z-index: 9999;
}


.menu ul{
    padding-left: 0;
    display: flex;
    list-style: none;
    margin-top: 2px;
    justify-content: space-evenly;
    text-decoration: none;
    margin-bottom: 2px;
    align-content: center;
}

.menu ul li{
    width: 120px;
    text-align: center;
}

@media (max-width: 645px) {
    .menu ul li{
        width: 80px;
    }
}

div.menu ul li a{
    color: rgb(98, 98, 98);
    font-size: 14px;
    padding: 5px 30px;
    text-decoration: none;
    font-family: 'Montserrat';
    width: max-content;
}

div.menu ul li a{
    transition: all 0.3s linear;
}

div.menu ul li a:hover {
    color: rgba(255, 170, 0);
    font-family: 'Montserrat';
    box-shadow: 0 -5px 0.3rem -3px rgb(255, 170, 0),
                0 5px 0.3rem -3px rgb(255, 170, 0);
    cursor: pointer;
}

@media (min-width: 2225px) {
    div.menu ul li a{
        font-size: 20px;
    }
}
@media (max-width: 1294px) {
    div.menu ul li a{
        font-size: 12px;
    }
}
@media (max-width: 645px) {
    div.menu ul li a{
        font-size: 12px;
    }
}

.image-gallery {
    margin-top: 0.5cm;
    background-color: rgba(255, 255, 255, 0.3);
    overflow: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 5px 0px 0 0px;
    width: auto;
  }
  
.image-gallery img {
    display: inline-flex;
    justify-content: center;
    width: auto;
    height: 550px;
    border-radius: 0.3rem;
  }

  @media (min-width: 2225px) {
    .image-gallery, .image-gallery img{
        height: 650px;
        width: auto;
    }
}
@media (max-width: 1294px) {
    .image-gallery, .image-gallery img{
        height: 400px;
        width: auto;
    }
}
@media (max-width: 645px) {
    .image-gallery, .image-gallery img{
        height: 300px;
        width: auto;
    }
}

.ancla-album {
    margin: 0.5 0 0 0;
    background-color: #ffffff00;
    width: 100%;
    color: #ffffff00;
}

.ancla-album p{
    margin-bottom: 0;
}

.container-album {
    display: flex;
    justify-content: left;
    height: auto;
    margin: 0 0 1cm 0;
    font-size: x-small;
  }

.music {
    position: relative;
    top: 8cm;
    left: -0.5cm;
    justify-content: center;
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: rgb(220, 220, 220);
    text-shadow: rgb(220, 220, 220) 2px 0 10px;
    font-family:'Shadows Into Light';
    font-size: 2cm;
    height: fit-content;
    width: fit-content;
}


p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.music p {
    margin: 0;
}

@media (min-width: 2225px) {
    div.music p{
        font-size: 3cm;
    }
}
@media (max-width: 1294px) {
    div.music p{
        font-size: 1.5cm;
    }
}
@media (max-width: 645px) {
    div.music p{
        font-size: 1cm;
        margin: 0 0 25px 0;
    }
}

div.items{
    display: inline-block;
    position: relative;
    left: -2cm;
    width: max-content;
}

  
.item1 {
    display: flex;
    width: 110px;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    flex-direction: column;
    cursor: pointer;
    border-radius: 0.5rem;
    color: #ffffffb3;
  }

div.item1 {
    transition: all 0.2s ease;
}

div.item1:hover {
    box-shadow: rgb(0, 255, 225) 0 0 0.5cm;
    cursor: pointer;
    transform: scale(1.1);
}

.item1 p {
    font-family: 'Montserrat';
    margin: 5px 0 0 0;
    font-size:small;
}

.item2 {
    display: flex;
    width: 110px;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    flex-direction: column;
    cursor: pointer;
    border-radius: 0.5rem;
    color: #ffffffb3;
  }
  
div.item2 {
    transition: all 0.2s ease;
}

div.item2:hover {
    box-shadow: rgb(5, 63, 8) 0 0 1cm;
    cursor: pointer;
    transform: scale(1.1);
}

.item2 p {
    font-family: 'Montserrat';
    margin: 5px 0 0 0;
    font-size:small;
}

.item3 {
    display: flex;
    width: 110px;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    flex-direction: column;
    cursor: pointer;
    border-radius: 0.5rem;
    color: #ffffffb3;
  }
  
div.item3 {
    transition: all 0.2s ease;
}

div.item3:hover {
    box-shadow: rgb(194, 1, 253) 0 0 0.5cm;
    cursor: pointer;
    transform: scale(1.1);
}

.item3 p {
    font-family: 'Montserrat';
    margin: 5px 0 0 0;
    font-size:small;
}

.item4 {
    display: flex;
    width: 110px;
    margin: 10px;
    padding: 10px;
    justify-items: first baseline;
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    flex-direction: column;
    cursor: pointer;
    border-radius: 0.5rem;
    color: #ffffffb3;
  }
  
div.item4 {
    transition: all 0.2s ease;
}

div.item4:hover {
    box-shadow: rgb(0, 255, 157) 0 0 0.5cm;
    cursor: pointer;
    transform: scale(1.1);
}

.item4 p {
    font-family: 'Montserrat';
    margin: 5px 0 0 0;
    font-size:small;
}

.description-items{
    display:flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0.7rem;
    width: 100%;
    height: auto;
    margin: 2cm 10px 1.5cm 10px;
    padding: 3cm 3cm 0 30px;
    position: relative;
    left: -2cm;
    font-family: 'Montserrat';
    font-size: 0.5cm;
    box-shadow: rgb(0, 0, 0) 0 0 0.5cm;
}

@media (max-width: 645px) {
    .description-items {
        padding: 3cm 0.3cm 0 30px;
    }
}

div.titulo-portada1 {
    color: #ffffff;
    position: absolute;
    top: -0.8cm;
    left: 1.5cm;
    font-family:'Shadows Into Light';
    color: rgb(255, 255, 225);
    text-shadow: rgb(0, 255, 225) 0 0 0.5cm;
    font-size: 1.5cm;
}

div.titulo-portada2 {
    color: #ffffff;
    position: absolute;
    top: -0.8cm;
    left: 1.5cm;
    font-family:'Shadows Into Light';
    color: rgb(255, 255, 225);
    text-shadow: rgb(5, 63, 8) 0 0 0.5cm;
    font-size: 1.5cm;
}

div.titulo-portada3 {
    color: #ffffff;
    position: absolute;
    top: -0.8cm;
    left: 1.5cm;
    font-family:'Shadows Into Light';
    color: rgb(255, 255, 225);
    text-shadow: rgb(194, 1, 253) 0 0 0.5cm;
    font-size: 1.5cm;
}

div.titulo-portada4 {
    color: #ffffff;
    position: absolute;
    top: -0.8cm;
    left: 1.5cm;
    font-family:'Shadows Into Light';
    color: rgb(255, 255, 225);
    text-shadow: rgb(0, 255, 157) 0 0 0.5cm;
    font-size: 1.5cm;
}

@media (min-width: 2225px) {
    div.titulo-portada1, div.titulo-portada2, div.titulo-portada3, div.titulo-portada4 {
        font-size: 2cm;
        top: -1.6cm;
        margin-left: 1cm;
    }
}
@media (max-width: 1294px) {
    div.titulo-portada1, div.titulo-portada2, div.titulo-portada3, div.titulo-portada4 {
        top: 0cm;
        font-size: 1.2cm;
    }
}
@media (max-width: 645px) {
    div.titulo-portada1, div.titulo-portada2, div.titulo-portada3, div.titulo-portada4 {
        top: 1cm;
        left: 30px;
        font-size: 0.6cm;
    }
}

@media (min-width: 2225px) {
    .description-items {
        margin-top: 0;
        margin-bottom: -0.4cm;
    }
}

@media (min-width: 2225px) {
    .video {
        width: 1040px;
        height: 600px;
        margin: -2cm 0.5cm 0.5cm 7cm;
    }
}

.canciones {
    color: rgb(220, 221, 222);
    display:inline-block;
    width: max-content;
    height: fit-content;
    padding: 0 0 0 30px;
    position: relative;
    text-align: left;
    font-family: 'Montserrat';
    font-size: 0.42cm;
    place-content: center;
}

.canciones p{
    text-shadow: #000000 10px 0 1cm;
    width: auto;
}

@media (min-width: 2225px) {
    div.canciones {
        font-size: 0.62cm;
        width: 100%;
        margin-left: 1cm;
    }
}
@media (max-width: 890px) {
    div.canciones {
        font-size: 0.42cm;
        width: 100%;
    }
}
@media (max-width: 645px) {
    div.canciones {
        display: inline;
        font-size: 0.25cm;
        width: 4cm;
        padding-left: 0;
        padding-right: 4.5cm;
    }
}


.video {
    position: absolute;
    top: 3.5cm;
    left: 11cm;
    border-radius: 0.5rem;
    box-shadow: rgb(0, 0, 0) 0 0 0.3cm;
}


@media (max-width: 1265px) {
    .video{
        display: none;
    }
}

@media (max-width: 890px) {
    .video{
        display: none;
    }
}
@media (max-width: 645px) {
    .video{
        display: none;
    }
}


.description-items {
    display: none;
  }


img {
    width: 100%;
    height: auto;
  }


div.footer p{
    color: #ffffff;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 12px;
  }


div.follow p{
    color: rgb(255, 170, 0);
    text-align: center;
    font-family: 'Montserrat';
    font-size: 16px;
    margin-top: 0.5cm;
    margin-bottom: 0.4cm;
  }

div.footer p{
    margin: 0.1cm 0 1cm 0;
  }

.container-media {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
}

.container-media a {
    margin: 0cm 0.15cm 0 0.15cm;
}

.container-media img {
    height: 40px;
    margin-top: 0;
}

div.container-media a{
    transition: all 0.2s ease;
}

div.container-media a:hover {
    transform: scale(1.2);
}

  
.container-media-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    color: #ffffff;
    margin: 1cm 0 0 0;
}

.container-media-footer a {
    margin: 0cm 0.15cm 0 0.15cm;
}

.container-media-footer img {
    height: 40px;
    margin-top: 0;
}
  
div.container-media-footer a{
    transition: all 0.2s ease;
}

div.container-media-footer a:hover {
    transform: scale(1.2);
}

@media (min-width: 2225px) {
    div.container-media img{
        height: 75px;
        width: auto;
    }
}
@media (max-width: 1294px) {
    div.container-media img, .container-media-footer img{
        height: 40px;
        width: auto;
    }
}
@media (max-width: 645px) {
    div.container-media img, .container-media-footer img{
        height: 30px;
        width: auto;
    }
}


@media (min-width: 2225px) {
    div.follow p{
        font-size: 20px;
    }
}
@media (max-width: 1294px) {
    div.follow p{
        font-size: 12px;
    }
}
@media (max-width: 645px) {
    div.follow p{
        font-size: 12px;
    }
}